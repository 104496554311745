import type { Region } from '@experiences/constants';
import { useGetErrorInfo } from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type {
    ICreateEditTenantPayload,
    ITenant,
} from '@experiences/interfaces';
import {
    SpacingToken,
    UiProgressButton,
    UiStack,
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import isString from 'lodash/isString';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { useEnabledTenantOperations } from '../../../common/hooks/useEnabledTenantOperations';
import { useTenantOperations } from '../../../common/hooks/useTenantOperations';
import BreadcrumbProvider, { useBreadcrumbs } from '../../../common/providers/BreadcrumbProvider';
import { leaseUrl } from '../../../services/licensing/accountant/LeaseService';
import {
    getAvailableServices,
    getTenantById,
    tenantAvailableServicesUri,
    tenantByIdUri,
} from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    isAdminSelector,
    isOrgAdminSelector,
} from '../../../store/selectors';
import validateName from '../../../util/validators/NameValidator';
import { UiDeleteButton } from '../../common/UiDeleteButton/UiDeleteButton';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import { UiTenantStatusBanner } from '../../common/UiTenantStatusBanner';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import TenantDeleteDialogBody from '../subcomponents/TenantDeleteDialogBody';
import TenantStatusDialogBody from '../subcomponents/TenantStatusDialogBody';
import { TenantStatusConstants } from '../TenantConstants';
import { useTenantOperationTrackerContext } from '../TenantOperationTrackerContextProvider';
import {
    serviceInstanceUrl,
    useTenantsContext,
} from '../TenantsContextProvider';
import TenantGeneralForm from './TenantGeneralForm';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        centerLoader: { margin: 'auto' },
        deleteButton: {
            color: `${theme.palette.semantic.colorErrorText} !important`,
            '&:hover': {
                backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important`,
                borderColor: `${theme.palette.semantic.colorErrorText} !important`,
            },
            borderColor: `${theme.palette.semantic.colorErrorText} !important`,
        },
        divider: { margin: '24px 0px' },
        error: {
            margin: '20px 24px',
            flex: '1 1 100%',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            lineHeight: '20px',
        },
        sectionButton: {
            marginRight: '10px',
            marginTop: '20px',
            width: '120px',
        },
        statusButton: { '&.Mui-disabled': { pointerEvents: 'auto' } },
        tenantSettingsContainer: {
            display: 'flex',
            width: '100%',
        },
        moveTenantBox: {
            marginTop: '24px',
            marginBottom: '20px',
            margin: '0 2px',
        },
        moveTenantTitle: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForeground,
        },
        moveTenantTitleSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '16px',
        },
        chipSpacer: { marginLeft: '8px' },
    }),
);

const TenantSettingsComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const tenantOperations = useTenantOperations();
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();
    const createDialog = useShowDialog();
    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const { isUserEnterprise } = useCheckLicense();

    const isAdmin = useSelector(isAdminSelector);
    const isOrgAdmin = useSelector(isOrgAdminSelector);
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);

    const EnableGetTenantsRedesign = useFeatureFlagValue(Features.EnableGetTenantsRedesign.name);
    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);

    const { data: tenantContextData } = useTenantsContext();
    const { checkOperationList } = useTenantOperationTrackerContext();
    const { canEnableTenant } = useEnabledTenantOperations(tenantContextData, isAdmin, false);
    const [ errorMessage, setErrorMessage ] = useState<string | undefined>(undefined);
    const [ loading, setLoading ] = useState(false);

    const { tenantId } = useParams() as { tenantId: string };

    const { mutate } = useSWRConfig();

    const { data: tenant } = useSWR(
        tenantId ? {
            url: tenantByIdUri,
            id: tenantId,
        } : null,
        getTenantById,
    );

    const { data: availableServices } = useSWR(
        {
            url: tenantAvailableServicesUri,
            organizationGuid: partitionGlobalId,
            accountName,
        },
        getAvailableServices,
    );

    const methods = useForm<ICreateEditTenantPayload>({
        mode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            name: tenant?.name ?? '',
            color: tenant?.color ?? '',
            region: (tenant?.region as Region) ?? '',
            services: [],
        },
    });

    const initialValuesRef = useMemo<ICreateEditTenantPayload>(() => ({
        name: tenant?.name ?? '',
        color: tenant?.color ?? '',
        region: (tenant?.region as Region) ?? '',
        services: tenant?.tenantServiceInstances.map(service => service.serviceType) ?? [],
    }), [ tenant ]);

    const canaryNotEnterprise = useMemo(() =>
        tenant?.isCanaryTenant && !isUserEnterprise,
    [ isUserEnterprise, tenant?.isCanaryTenant ]);

    const {
        handleSubmit, setError, reset, formState: { isDirty },
    } = methods;

    const isStatusButtonDisabled = useMemo(() => {
        if (tenant?.id) {
            return checkOperationList(tenant.id) ||
            (canaryNotEnterprise) ||
            (!canEnableTenant && tenant?.status.toUpperCase() === TenantStatusConstants.DISABLED
            || tenant?.status.toUpperCase() === TenantStatusConstants.UPDATING);
        }
        return false;
    }, [ canEnableTenant, checkOperationList, tenant?.id, tenant?.status, canaryNotEnterprise ]);

    const clickedDisableTenant = useCallback(
        async (data?: ITenant) => {
            if (!data) {
                return;
            }

            await createDialog({
                title: translate({ id: 'CLIENT_TENANT_CONFIRMATION_DISABLE_HEADER' }),
                icon: 'warning',
                customDialogContent: TenantStatusDialogBody,
                customDialogContentProps: {
                    tenant: data,
                    status: TenantStatusConstants.DISABLE,
                    tenantStatusModify: tenantOperations.tenantStatusModify,
                },
            });
        },
        [ createDialog, translate, tenantOperations.tenantStatusModify ],
    );

    const clickedEnableTenant = useCallback(
        async (data?: ITenant) => {
            if (!data) {
                return false;
            }

            await createDialog({
                title: translate({ id: 'CLIENT_TENANT_CONFIRMATION_ENABLE_HEADER' }),
                icon: 'warning',
                customDialogContent: TenantStatusDialogBody,
                customDialogContentProps: {
                    tenant: data,
                    status: TenantStatusConstants.ENABLE,
                    tenantStatusModify: tenantOperations.tenantStatusModify,
                },
            });
        },
        [ createDialog, translate, tenantOperations.tenantStatusModify ],
    );

    const clickedDeleteTenant = useCallback(
        async (data?: ITenant) => {
            if (!data) {
                return false;
            }

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_TENANT' }),
                icon: 'error',
                customDialogContent: TenantDeleteDialogBody,
                customDialogContentProps: {
                    tenant: data,
                    tenantDelete: tenantOperations.tenantDelete,
                },
            });
            if (proceed) {
                navigate(getRoute(RouteNames.OrganizationAdminHome));
            }
        },
        [ createDialog, getRoute, navigate, tenantOperations.tenantDelete, translate ],
    );

    const { breadcrumbs } = useBreadcrumbs();

    useEffect(() => {
        if (tenant && availableServices) {
            reset(initialValuesRef);
        }
    }, [ availableServices, initialValuesRef, reset, tenant ]);

    const updateTenantStatus = useCallback(
        async (data?: ITenant) => {
            if (!data) {
                return;
            }

            const proceed = data.status.toUpperCase() === TenantStatusConstants.DISABLED
                ? await clickedEnableTenant(data)
                : await clickedDisableTenant(data);
            if (proceed) {
                try {
                    setLoading(true);
                } catch (error) {
                    setErrorMessage(await getErrorMessage(error));
                } finally {
                    setLoading(false);
                }
            }
        },
        [ clickedDisableTenant, clickedEnableTenant, getErrorMessage ],
    );

    const discardChanges = useCallback(() => {
        reset(initialValuesRef);
    },
    [ initialValuesRef, reset ]);

    const onSubmit = useCallback(
        async (data: ICreateEditTenantPayload) => {
            setLoading(true);

            data.name = data.name.trim();

            if (!validateName(data.name)) {
                setError('name', { type: 'invalid' });
                setLoading(false);
                return;
            }

            try {
                const proceed = data.name !== tenant?.name
                    ? await createDialog({
                        title: translate({ id: 'CLIENT_CHANGE_SERVICE_NAME' }),
                        body: (
                            <div>
                                <UiText>
                                    <FormattedMessage
                                        id={
                                            process.buildConfigs.disableUserInvite || DisableFeatureFedRamp
                                                ? 'CLIENT_WARNING_MESSAGE_FOR_EDIT_SERVICE_NAME_NO_INVITE'
                                                : 'CLIENT_WARNING_MESSAGE_FOR_EDIT_SERVICE_NAME'
                                        }
                                        values={{
                                            strong: (chunk: React.ReactNode[]) =>
                                                <strong>
                                                    {chunk}
                                                </strong>,
                                        }}
                                    />
                                </UiText>
                                <UiText style={{ marginTop: '8px' }}>
                                    {translate({ id: 'CLIENT_PROCEED_MESSAGE' })}
                                </UiText>
                            </div>
                        ),
                        icon: 'warning',
                        showCancel: true,
                        unclosable: true,
                        primaryButtonText: translate({ id: 'CLIENT_PROCEED' }),
                        dataCy: 'create-edit-tenant-confirmation-dialog',
                    })
                    : true;
                if (proceed && tenant) {
                    await tenantOperations.tenantEdit(data, tenant, {});
                    reset(data);
                } else {
                    setLoading(false);
                    return;
                }

                setLoading(false);

                mutate(
                    !EnableGetTenantsRedesign
                        ? {
                            url: serviceInstanceUrl,
                            organizationGuid: partitionGlobalId,
                            accountName,
                            includeTenantServices: true,
                        }
                        : {
                            url: serviceInstanceUrl,
                            organizationGuid: partitionGlobalId,
                            accountName,
                            tenantStatus: 'All',
                            includeTenantServices: true,
                        },
                );
                mutate(leaseUrl);
            } catch (error) {
                const errorObject = await getErrorObject(error);

                if (errorObject.response?.status === 504) {
                    setErrorMessage(translate({ id: 'CLIENT_CONNECTION_TIMEOUT_MESSAGE' }));
                } else {
                    const errorData = errorObject.response?.data;
                    const errorResponse = isString(errorData) ? errorData : await getErrorMessage(errorObject);
                    setErrorMessage(errorResponse);
                }
                setLoading(false);
            }
        },
        [
            setError,
            tenant,
            createDialog,
            translate,
            DisableFeatureFedRamp,
            EnableGetTenantsRedesign,
            partitionGlobalId,
            accountName,
            tenantOperations,
            reset,
            getErrorObject,
            getErrorMessage,
            mutate,
        ],
    );

    const errorToDisplay = useCallback((message: string) => {
        if (message?.includes('Another operation')) {
            return translate({ id: 'CLIENT_OPERATION_IN_PROGRESS_ERROR_MESSAGE' });
        }
        return message;
    }, [ translate ]);

    return (
        <UiPageContainer
            position='center'
            banner={
                <UiTenantStatusBanner
                    canEnableTenant={canEnableTenant}
                    status={tenant?.status}
                    tenantName={tenant?.name} />
            }
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            loading={!tenant || !availableServices}
            maxWidth="900px"
        >
            {!!errorMessage && (
                <div
                    className={classes.error}
                    data-cy="add-edit-tenant-error">
                    <UiText>
                        {errorToDisplay(errorMessage)}
                    </UiText>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            setErrorMessage('');
                        }}
                        style={{ marginTop: '12px' }}
                        data-cy="add-edit-tenant-retry"
                    >
                        {translate({ id: 'CLIENT_RETRY' })}
                    </Button>
                </div>
            )}
            {!errorMessage && (<UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    isDirty
                        ? <div className={classes.actions}>
                            <Button
                                className={classes.cancelButton}
                                onClick={() => discardChanges()}
                                color="primary"
                                data-cy="add-edit-tenant-discard"
                            >
                                {translate({ id: 'CLIENT_DISCARD' })}
                            </Button>
                            <UiProgressButton
                                type="submit"
                                loading={loading}
                                variant="contained"
                                data-cy="tenant-settings-submit-button"
                            >
                                {translate({ id: 'CLIENT_SAVE_CHANGES' })}
                            </UiProgressButton>
                        </div>
                        : undefined
                }
                centerChild
                dataCy="tenant-settings-component"
            >
                {!!tenant && (
                    <FormProvider {...methods}>
                        <TenantGeneralForm
                            disableRegion
                            disableForm={!isOrgAdmin
                                    || checkOperationList(tenant.id)
                                    || tenant.status.toUpperCase() === TenantStatusConstants.DISABLED
                                    || tenant.status.toUpperCase() === TenantStatusConstants.UPDATING}
                            tenant={tenant}
                            type="edit" />
                    </FormProvider>
                )}

                <Divider className={classes.divider} />
                <UiStack direction="column">
                    <UiText className={classes.inputLabel}>
                        {translate({
                            id: tenant?.status.toUpperCase() === TenantStatusConstants.ENABLED
                                ? 'CLIENT_TENANT_CONFIRMATION_DISABLE_HEADER'
                                : 'CLIENT_TENANT_CONFIRMATION_ENABLE_HEADER',
                        })}
                    </UiText>
                    <UiText data-cy={`tenant-status-description-${tenant?.status.toLocaleLowerCase()}`}>
                        {canaryNotEnterprise
                            ? translate({ id: 'CLIENT_CANARY_TENANT_DISABLED_WARNING' })
                            : translate({
                                id: tenant?.status.toUpperCase() === TenantStatusConstants.ENABLED
                                    ? 'CLIENT_TENANT_SETTINGS_DISABLE_WARNING'
                                    : 'CLIENT_TENANT_SETTINGS_ENABLE_WARNING',
                            }, { 0: tenant?.name })}
                    </UiText>
                    <Tooltip
                        title={!canEnableTenant && tenant?.status.toUpperCase() === TenantStatusConstants.DISABLED
                            ? translate({ id: 'CLIENT_MAX_ENABLED_SERVICE_INSTANCE_COUNT_REACHED_REVAMP' }) : ''}
                        placement='bottom-start'>
                        <div style={{
                            display: 'inline-block',
                            width: 'fit-content',
                        }}>
                            <Button
                                className={clsx(classes.sectionButton, classes.statusButton)}
                                onClick={() => updateTenantStatus(tenant)}
                                variant="outlined"
                                data-cy="tenant-settings-toggle-status-button"
                                disabled={isStatusButtonDisabled || !isOrgAdmin}
                            >
                                {tenant?.status.toUpperCase() === TenantStatusConstants.ENABLED
                                    ? translate({ id: 'CLIENT_DISABLE' })
                                    : translate({ id: 'CLIENT_ENABLE' })}
                            </Button>
                        </div>
                    </Tooltip>
                </UiStack>
                <Divider className={classes.divider} />

                <UiStack
                    direction="column"
                    gap={SpacingToken.M}>
                    <UiStack direction="column">
                        <UiText className={classes.inputLabel}>
                            {translate({ id: 'CLIENT_TENANT_SETTINGS_DELETE_HEADER' })}
                        </UiText>
                        <UiText>
                            {translate({ id: 'CLIENT_TENANT_SETTINGS_DELETE_WARNING' }, { 0: tenant?.name })}
                        </UiText>
                    </UiStack>
                    <UiStack>
                        <UiDeleteButton
                            onClick={() => clickedDeleteTenant(tenant)}
                            disabled={!isOrgAdmin}
                            data-cy="tenant-settings-delete-button"
                        />
                    </UiStack>
                </UiStack>
            </UiForm>)}
        </UiPageContainer>
    );
};

export const TenantSettingsComponentWithProvider: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const { tenantId } = useParams() as { tenantId: string };

    const { data: tenant } = useSWR(
        tenantId ? {
            url: tenantByIdUri,
            id: tenantId,
        } : null,
        getTenantById,
    );

    const breadCrumbLinks = useMemo(() =>
        [
            {
                index: 0,
                link: RouteNames.TenantHome.replace(':tenantId', tenantId),
                name: tenant?.name ?? '',
            },
            {
                index: 1,
                link: RouteNames.TenantSettings.replace(':tenantId', tenantId),
                name: translate({ id: 'CLIENT_SETTINGS' }),
            },
        ],
    [ tenant, tenantId, translate ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <TenantSettingsComponent />
        <UiSuspensefulOutlet />
    </BreadcrumbProvider>;
};

export default TenantSettingsComponent;
