import { getRegionTranslationId } from '@experiences/constants';
import type { ICreateEditTenantPayload } from '@experiences/interfaces';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import type { ControllerRenderProps } from 'react-hook-form';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DataResidencyCloudLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { checkServiceRegion } from '../../../common/hooks/useCheckRegionService';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import useIsDedicated from '../../../common/hooks/useIsDedicated';
import useIsOnPrem from '../../../common/hooks/useIsOnPrem';
import type { IService } from '../../../common/interfaces/tenant/tenant';
import UiCardGrid from '../../common/UiCardGrid/UiCardGrid';
import { ServiceLicenseStatus } from '../../licensing/interfaces/license';
import type {
    IServiceMetadata,
    IServiceRegionMap,
} from '../interfaces/service';
import { serviceOrder } from '../TenantConstants';
import { getHiddenServices } from './helpers/HiddenServicesHelper';
import {
    defaultServicesProvisioned,
    getListOfDependencies,
    getListOfParents,
} from './helpers/ServiceDependencyGraph';
import { getDefaultRegionForTenant } from './helpers/TenantRegionHelper';
import type { ServiceErrorType } from './helpers/TenantServiceErrorMessage';
import TenantServiceErrorMessage from './helpers/TenantServiceErrorMessage';
import { useServiceDependency } from './helpers/useServiceDependency';
import validateTenantService from './helpers/ValidateTenantService';
import { TenantServiceCard } from './TenantServiceCardComponent';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        tenantServiceForm: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
            justifyContent: 'right',
        },
        serviceCard: {
            display: 'flex',
            flexDirection: 'column',
        },
        drawerMessage: { margin: '24px 12px 20px 0px' },
        needMoreServices: {
            boxSizing: 'border-box',
            border: 'solid 1px',
            padding: '16px',
            borderRadius: '4px',
        },
        upgradeInfo: {
            fontWeight: 400,
            fontSize: '14px',
            marginTop: '8px',
        },
        upgradeHeader: {
            fontWeight: 600,
            fontSize: '14px',
        },
        selectAllCheckbox: { alignSelf: 'flex-end' },
    }),
}));

const CreateEditTenantFormComponent: React.FC<{
    type: 'add' | 'edit';
    services: IService[];
    isRegionEnabled: boolean;
    validationErrorHandler: [
        Record<ServiceErrorType, string[]>,
        React.Dispatch<React.SetStateAction<Record<ServiceErrorType, string[]>>>,
    ];
    availableServices: IServiceMetadata[] | undefined;
    servicesAltRegion?: IServiceRegionMap;
    defaultRegion?: string;
}> = ({
    type, services, isRegionEnabled, validationErrorHandler, servicesAltRegion, availableServices, defaultRegion,
}) => {

    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'ru' ] });

    const { formatMessage: translate } = useIntl();
    const { servicesToHide } = useServiceDependency();
    const { isFreeOrCommunityRevamp } = useCheckLicense();
    const isDedicated = useIsDedicated();
    const isOnPrem = useIsOnPrem();

    const [ serviceDependencyMessages, setServiceDependencyMessages ] = useState<string[]>([ ]);
    const [ showRegionMessage, setShowRegionMessage ] = useState<boolean>(false);
    const [ checkSelectAll, setCheckSelectAll ] = useState<boolean>(true);

    const {
        control, setValue, getValues, watch,
    } = useFormContext<ICreateEditTenantPayload>();

    const [ validationErrors, setValidationErrors ] = validationErrorHandler;

    const region = watch('region');

    useEffect(() => {
        if (region) {
            return;
        }
        const defaultRegionCommunityFree = getDefaultRegionForTenant(availableServices);
        if (defaultRegionCommunityFree) {
            setValue('region', defaultRegionCommunityFree);
        }
    }, [ setValue, region, availableServices ]);

    const hiddenServices = useMemo(() => getHiddenServices(services, availableServices), [ availableServices, services ]);

    const checkedServices = useMemo(
        () => {
            if (type === 'add' &&
                getValues('services')?.filter(serviceIterator => !hiddenServices.includes(serviceIterator)).length > 0) {
                return getValues('services');
            }
            return type === 'add'
                ? (availableServices ?? [])
                    .filter(
                        service =>
                            service.provisioningMode === 'Implicit' &&
                            service.serviceLicenseStatus === ServiceLicenseStatus.Available,
                    )
                    .map(service => service.id)
                : services.map(service => service.serviceType);
        },
        [ type, getValues, availableServices, services, hiddenServices ],
    );
    const editAvailableServices = useMemo(() => availableServices?.filter(i => !checkedServices.includes(i.id))
        , [ availableServices, checkedServices ]);

    const displayedServices = useMemo(() => {
        const data = type === 'add' ? availableServices : editAvailableServices;
        return data
            ?.filter(service => servicesToHide.indexOf(service.id) < 0 && hiddenServices.indexOf(service.id) < 0)
            .sort(
                (serviceA: IServiceMetadata, serviceB: IServiceMetadata) =>
                    serviceOrder.indexOf(serviceA.id) - serviceOrder.indexOf(serviceB.id),
            );
    }, [ availableServices, editAvailableServices, hiddenServices, servicesToHide, type ]);

    const handleCheck = useCallback(
        (checkedId: string) => {
            const { services: ids } = getValues();
            if (ids.indexOf(checkedId) > -1) {
                setServiceDependencyMessages(prev => prev.filter(t => t !== checkedId));
            }
            return ids.indexOf(checkedId) > -1 ? ids.filter(id => id !== checkedId) : [ ...(ids ?? []), checkedId ];
        },
        [ getValues ],
    );

    const validate = useCallback(async () => {
        if (!availableServices) {
            return;
        }

        const currentServices = getValues('services');

        const validatedServices = validateTenantService(
            availableServices.filter(s => s.id !== 'bupproxyservice'),
            services,
            currentServices,
            isRegionEnabled ? {
                region,
                servicesAltRegion,
            } : {},
        );

        setValidationErrors(validatedServices);

        if (currentServices && validatedServices.shouldDisableRevamp.length) {
            setValue(
                'services',
                currentServices.filter(s => validatedServices.shouldDisableRevamp.indexOf(s) === -1),
            );
        }
    }, [
        availableServices,
        getValues,
        services,
        isRegionEnabled,
        region,
        servicesAltRegion,
        setValidationErrors,
        setValue,
    ]);

    useEffect(() => {
        if (checkedServices) {
            setValue('services', checkedServices);
        }
    }, [ checkedServices, setValue ]);

    // validate services based on region changes
    useEffect(() => {
        validate();
    }, [ region, validate ]);

    const watchedServices = watch('services');

    useEffect(() => {
        setShowRegionMessage(watchedServices.some((iterator) => {
            const displayServicesFilter = displayedServices?.find((service) => service.id === iterator);
            return displayServicesFilter ? !checkServiceRegion(displayServicesFilter, region) : false;
        }));
    }, [ watchedServices, displayedServices, region ]);

    const serviceDependencyMessage = useCallback((service: IServiceMetadata) => {

        const parentDependency =
            type === 'edit'
                ? getListOfDependencies(service.id)
                    .filter(s => !services.some(serviceIterator => serviceIterator.serviceType === s) && !!s)
                    .map(s => availableServices?.find(k => k.id === s)?.name ?? '')
                    .join(', ')
                : getListOfDependencies(service.id)
                    .map(s => availableServices?.find(k => k.id === s)?.name ?? '')
                    .filter(s => !!s)
                    .join(', ') ;

        return parentDependency ?
            translate({ id: 'CLIENT_SERVICE_DEPENDENCY' }, {
                serviceName: service.name,
                parentName: parentDependency,
            })
            : '';
    }, [ availableServices, services, translate, type ]);

    const handleDependencyCheck = useCallback((service: IServiceMetadata) => {
        const parentServices = getListOfDependencies(service.id);
        const childServices = getListOfParents(service.id);
        const currentServices = getValues('services');

        if (currentServices.some(serviceIterator => serviceIterator === service.id)) {
            if (parentServices && !parentServices.every(iterator => currentServices.includes(iterator))) {
                setServiceDependencyMessages(t => !t?.includes(service.id) ? [ ...t, service.id ] : t);
                setValue('services', currentServices.concat(parentServices));
                validate();
            }
        } else {
            if (childServices) {
                setServiceDependencyMessages(t => t.filter(iterator => !childServices.includes(iterator)));
                setValue('services', currentServices.filter(serviceIterator => !childServices.includes(serviceIterator)));
                validate();
            }
        }
    }, [ getValues, setValue, validate ]);

    const addAllServices = useCallback(() => {
        setCheckSelectAll((prevState) => !prevState);
        if (checkSelectAll) {
            const servicesToAdd = availableServices
                ?.filter((serviceIterator) => serviceIterator.serviceLicenseStatus === ServiceLicenseStatus.Available)
                .map((serviceIterator) => serviceIterator.id) ?? [];
            setValue('services', servicesToAdd, { shouldDirty: true });
            validate();
        } else {
            setValue('services', defaultServicesProvisioned, { shouldDirty: true });
        }
    }, [ availableServices, checkSelectAll, setValue, validate ]);

    const renderTenantService = useCallback((props: ControllerRenderProps<ICreateEditTenantPayload, 'services'>) =>
        <>
            <div
                className={classes.tenantServiceForm}
                style={{
                    flexDirection: type === 'add' ? 'row' : 'column',
                    justifyContent: type === 'edit' || !showRegionMessage ? 'right' : 'space-between',
                }}
            >
                {type === 'add' ? showRegionMessage && <UiText data-cy="tenant-services-tenant-region-text">
                    {translate({ id: 'CLIENT_TENANT_CREATE_SERVICES_REGION' },
                        { region: translate({ id: getRegionTranslationId(region) }) })}
                </UiText> :
                    displayedServices?.some(
                        serviceIterator => serviceIterator.defaultRegion !== 'None' &&
                        checkServiceRegion(serviceIterator, defaultRegion)) && (
                        <div className={classes.drawerMessage}>
                            <UiText>
                                {translate(
                                    { id: 'CLIENT_DEFAULT_TENANT_REGION_DIFFERENT_SERVICE_ADD' },
                                    { 0: defaultRegion }) + ' '}
                                <Link
                                    data-cy="learn-region-hosting-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={getLocalizedLink({ articleSlug: DataResidencyCloudLink })}
                                >
                                    {translate({ id: 'CLIENT_AZURE_AD_CHECK_LINK_EMAILS_TOOLTIP_LEARN_MORE' })}
                                </Link>
                            </UiText>
                        </div>
                    )}
                <FormControlLabel
                    className={classes.selectAllCheckbox}
                    control={<Checkbox
                        value={checkSelectAll}
                        onClick={addAllServices}
                    />}
                    label={translate({ id: 'CLIENT_SELECT_ALL' })}
                    data-cy="tenant-services-add-all-checkbox" />
            </div>
            <UiCardGrid
                maxCardWidth={type === 'edit' ? '1000px' : '330px'}>
                {displayedServices
                    ?.map((service, i) => (
                        <div
                            key={`service-checkbox-${i}`}
                            className={classes.serviceCard}>
                            <TenantServiceCard
                                service={service}
                                clickable
                                cardChecked={props.value.indexOf(service.id) > -1 || service.isAlwaysProvision === true}
                                disableCheckBox={service.isAlwaysProvision === true}
                                onChangeHandler={() => {
                                    props.onChange(handleCheck(service.id));
                                    handleDependencyCheck(service);
                                    validate();
                                }}
                                TenantServiceErrorMessage={validationErrors ? (
                                    <TenantServiceErrorMessage
                                        errors={validationErrors}
                                        service={service}
                                        availableServices={availableServices ?? []}
                                        currentServices={watch('services')}
                                        servicesAltRegion={servicesAltRegion}
                                        services={services}
                                    />
                                ) : <></>}
                                region={region}
                                validationErrors={validationErrors} />
                            {serviceDependencyMessages?.includes(service.id) && <UiText
                                style={{
                                    fontSize: '12px',
                                    margin: '4px 0px 0px 4px',
                                }}
                                data-cy={`service-dependency-${service.id}`}>
                                {serviceDependencyMessage(service)}
                            </UiText>}
                        </div>
                    ))}
                {
                    (!isOnPrem && !isDedicated && isFreeOrCommunityRevamp && type === 'edit') ?
                        <div className={classes.needMoreServices}>
                            <UiText className={classes.upgradeHeader}>
                                {translate({ id: 'CLIENT_NEED_MORE_SERVICES' })}
                            </UiText>
                            <UiText className={classes.upgradeInfo}>
                                {translate({ id: 'CLIENT_UPGRADE_TO_TRY' })}
                            </UiText>
                            <Button
                                style={{ marginTop: '20px' }}
                                onClick={() => navigate(getRoute(RouteNames.BuyProPresets))}
                                variant='outlined'>
                                {translate({ id: 'CLIENT_UPGRADE' })}
                            </Button>
                        </div>
                        : <></>
                }
            </UiCardGrid>
        </>,
    [
        classes.tenantServiceForm,
        classes.drawerMessage,
        classes.selectAllCheckbox,
        classes.needMoreServices,
        classes.upgradeHeader,
        classes.upgradeInfo,
        classes.serviceCard,
        type,
        showRegionMessage,
        translate,
        region,
        displayedServices,
        defaultRegion,
        getLocalizedLink,
        checkSelectAll,
        addAllServices,
        isOnPrem,
        isDedicated,
        isFreeOrCommunityRevamp,
        validationErrors,
        availableServices,
        watch,
        servicesAltRegion,
        services,
        serviceDependencyMessages,
        serviceDependencyMessage,
        handleCheck,
        handleDependencyCheck,
        validate,
        navigate,
        getRoute,
    ]);

    return (
        <FormGroup>
            <Controller
                name="services"
                control={control}
                render={({ field }) => (
                    <>
                        {!displayedServices && (
                            <CircularProgress
                                style={{ margin: 'auto' }}
                                data-cy="tenant-services-loader" />)}
                        {displayedServices && displayedServices.length > 0 ? (
                            <>
                                {type === 'edit' && (
                                    <UiText data-cy="create-edit-tenant-service-message">
                                        {translate({ id: 'CLIENT_PROVISION_SERVICES_MESSAGE' })}
                                    </UiText>
                                )}
                                {renderTenantService(field)}
                            </>)
                            : (
                                <UiText data-cy="create-edit-tenant-empty-service">
                                    {translate({ id: 'CLIENT_PROVISION_SERVICES_EMPTY' })}
                                </UiText>
                            )}
                    </>
                )}
            />
        </FormGroup>
    );
};

export default CreateEditTenantFormComponent;
