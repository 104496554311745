import {
    SpacingToken,
    UiCopyButton,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import { useFormKeysValidation } from '@experiences/util';
import Close from '@mui/icons-material/Close';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import {
    ApAlertBar,
    ApButton,
    ApProgressSpinner,
} from '@uipath/portal-shell-react';
import React, {
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useImmer } from 'use-immer';

import validateCertificate from '../helpers/ValidateSAMLCert';
import useSAMLConfigureIDPViewModel from './SAMLConfigureIDPViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        columnBorder: {
            border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            borderRadius: '6px',
            padding: '20px',
        },
        centerSpinner: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        header: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        field: { marginTop: '8px' },
    }),
);

const SAMLConfigureIDPPageComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const {
        loading,
        methods: {
            register,
            formState: { errors },
            watch,
        },
        metadataStatus,
        metadataUrls,
        fetchData,
        clearMetadata,
        getFetchHelperText,
        handleChangeAuthId,
    } = useSAMLConfigureIDPViewModel();

    const [ configuration, setConfiguration ] = useImmer({
        metadataUrl: '',
        singleSignOnServiceUrl: '',
        identityProviderEntityId: '',
        signingCertificateText: '',
    });

    useEffect(() => {
        const subscription = watch((value) => {
            setConfiguration(draft => {
                draft.metadataUrl = value.IdentityProviderMetadataUrl ?? '';
                draft.singleSignOnServiceUrl = value.SingleSignOnServiceUrl ?? '';
                draft.identityProviderEntityId = value.IdentityProviderEntityId ?? '';
                draft.signingCertificateText = value.SigningCertificateLocation?.CertificateText ?? '';
            });
        });

        return () => subscription.unsubscribe();
    }, [ setConfiguration, watch ]);

    const metadataUrlRequired = useMemo(() =>
        (!!configuration.metadataUrl && !metadataStatus.success)
            || !configuration.singleSignOnServiceUrl
            || !configuration.identityProviderEntityId
            || !configuration.signingCertificateText,
    [ configuration, metadataStatus ]);

    const activeKeys = useMemo(() => {
        if (metadataUrlRequired) {
            return [ 'IdentityProviderMetadataUrl' ];
        }
        return [
            'SingleSignOnServiceUrl',
            'IdentityProviderEntityId',
            'SigningCertificateLocation.CertificateText',
        ];
    }, [ metadataUrlRequired ]);

    useFormKeysValidation(activeKeys);

    if (loading) {
        return <ApProgressSpinner className={classes.centerSpinner} />;
    }

    return <UiStack
        direction="column"
        mt={SpacingToken.M}
        align="center"
        gap={SpacingToken.L}>
        <UiStack
            className={classes.columnBorder}
            direction="column"
            w={700}
            gap={SpacingToken.S}>
            <UiStack
                align="center"
                justify="between">
                <UiText className={classes.header}>
                    {translate({ id: 'CLIENT_SAML_IDP_STEP_1' })}
                </UiText>
                <ApButton
                    variant="tertiary"
                    label={translate({ id: 'CLIENT_CHANGE_ENTITY_ID_FORMAT' })}
                    onClick={handleChangeAuthId} />
            </UiStack>
            <ApAlertBar cancelable={false}>
                <UiText>
                    {translate({ id: 'CLIENT_IDP_METADATA_DESCRIPTION' })}
                </UiText>
            </ApAlertBar>
            <UiStack
                direction="column"
                gap={SpacingToken.S}>
                <UiStack direction="column">
                    <UiStack align="center">
                        <UiText variant={FontVariantToken.fontSizeMBold}>
                            {translate({ id: 'CLIENT_METADATA_URL' })}
                        </UiText>
                        <UiCopyButton textToCopy={metadataUrls.metadataUrl} />
                    </UiStack>
                    <UiText>
                        {metadataUrls.metadataUrl}
                    </UiText>
                </UiStack>
                <UiStack direction="column">
                    <UiStack align="center">
                        <UiText variant={FontVariantToken.fontSizeMBold}>
                            {translate({ id: 'CLIENT_ASSERTION_CONSUMER_SERVICE_URL' })}
                        </UiText>
                        <UiCopyButton textToCopy={metadataUrls.assertionConsumerUrl} />
                    </UiStack>
                    <UiText>
                        {metadataUrls.assertionConsumerUrl}
                    </UiText>
                </UiStack>
                <UiStack direction="column">
                    <UiStack align="center">
                        <UiText variant={FontVariantToken.fontSizeMBold}>
                            {translate({ id: 'CLIENT_ENTITY_ID' })}
                        </UiText>
                        <UiCopyButton textToCopy={metadataUrls.entityId} />
                    </UiStack>
                    <UiText>
                        {metadataUrls.entityId}
                    </UiText>
                </UiStack>
            </UiStack>
        </UiStack>
        <UiStack
            direction="column"
            w={700}>
            <UiText className={classes.header}>
                {translate({ id: 'CLIENT_SAML_IDP_STEP_2' })}
            </UiText>
            <UiStack gap={SpacingToken.XS}>
                <UiStack
                    direction="column"
                    w="100%">
                    <TextField
                        required={metadataUrlRequired}
                        label={translate({ id: 'CLIENT_METADATA_URL' })}
                        InputLabelProps={{ id: 'metadataUrlLabel' }}
                        inputProps={{
                            'aria-labelledby': 'metadataUrlLabel',
                            ...register('IdentityProviderMetadataUrl', { required: metadataUrlRequired && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) }),
                        }}
                        InputProps={{
                            endAdornment: metadataStatus.success || metadataStatus.error
                                ? <IconButton onClick={clearMetadata}>
                                    <Close />
                                </IconButton>
                                : undefined,
                        }}
                        placeholder={translate({ id: 'CLIENT_ENTER_URL_HERE' })}
                        fullWidth
                        error={!!metadataStatus.error || !!errors.IdentityProviderMetadataUrl}
                        helperText={getFetchHelperText}
                        data-cy="metadata-url-field"
                        disabled={metadataStatus.loading}
                    />
                    {metadataStatus.success && <FormHelperText sx={theme => ({ color: theme.palette.semantic.colorSuccessText })}>
                        {`${translate({ id: 'CLIENT_FETCHED_METADATA_SUCCESSFULLY' })} ${translate({ id: 'CLIENT_SAML_FOLLOWING_FIELDS_READONLY' })}`}
                    </FormHelperText>}
                </UiStack>
                <ApButton
                    style={{ marginTop: 36 }}
                    label={translate({ id: 'CLIENT_FETCH' })}
                    variant="secondary"
                    loading={metadataStatus.loading}
                    onClick={fetchData}
                    disabled={metadataStatus.loading || !watch('IdentityProviderMetadataUrl')}
                    data-cy="metadata-url-fetch-now-button" />
            </UiStack>
            <UiStack direction="column">
                <TextField
                    required={!metadataStatus.success}
                    label={translate({ id: 'CLIENT_SIGN_ON_URL' })}
                    InputLabelProps={{ id: 'signOnUrlLabel' }}
                    inputProps={{
                        'aria-labelledby': 'signOnUrlLabel',
                        ...register('SingleSignOnServiceUrl', {
                            required: !metadataStatus.success,
                            validate: p => !!p.trim(),
                        }),
                    }}
                    InputProps={{ readOnly: metadataStatus.success }}
                    placeholder={translate({ id: 'CLIENT_ENTER_URL_HERE' })}
                    variant="outlined"
                    fullWidth
                    className={classes.field}
                    error={!!errors.SingleSignOnServiceUrl}
                    helperText={
                        errors.SingleSignOnServiceUrl?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                    }
                    data-cy="single-sign-on-service-url"
                    disabled={metadataStatus.loading || metadataStatus.success}
                />
                <TextField
                    required={!metadataStatus.success}
                    InputLabelProps={{ id: 'identityProviderEntityLabel' }}
                    inputProps={{
                        'aria-labelledby': 'identityProviderEntityLabel',
                        ...register('IdentityProviderEntityId', {
                            required: !metadataStatus.success,
                            validate: p => !!p.trim(),
                        }),
                    }}
                    InputProps={{ readOnly: metadataStatus.success }}
                    label={translate({ id: 'CLIENT_IDENTITY_PROVIDER_ENTITY_ID' })}
                    placeholder={translate({ id: 'CLIENT_ENTER_ID_HERE' })}
                    variant="outlined"
                    fullWidth
                    className={classes.field}
                    error={!!errors.IdentityProviderEntityId}
                    helperText={
                        errors.IdentityProviderEntityId?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                    }
                    data-cy="identity-provider-entity-id-field"
                    disabled={metadataStatus.loading || metadataStatus.success}
                />
                <TextField
                    required
                    error={!!errors.SigningCertificateLocation?.CertificateText}
                    fullWidth
                    className={classes.field}
                    inputProps={{
                        'aria-labelledby': 'signingCertificateThumbprintLabel',
                        ...register('SigningCertificateLocation.CertificateText', {
                            required: !metadataStatus.success && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }),
                            validate: { invalid: async p => (!!p.trim() && await validateCertificate(p.trim())) },
                        }),
                    }}
                    InputProps={{ readOnly: metadataStatus.success }}
                    label={translate({ id: 'CLIENT_SIGNING_CERTIFICATE_THUMBPRINT' })}
                    multiline
                    rows={4}
                    data-cy="signing-certificate-field"
                    disabled={metadataStatus.loading || metadataStatus.success}
                    variant="outlined"
                />
                <FormHelperText>
                    {(errors.SigningCertificateLocation?.CertificateText?.type === 'required'
                        && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                    || (errors.SigningCertificateLocation?.CertificateText?.type === 'invalid'
                        && translate({ id: 'CLIENT_SIGNING_CERTIFICATE_INVALID' }))
                    || translate({ id: 'CLIENT_SAML_CERT_HELPER_TEXT' })}
                </FormHelperText>
            </UiStack>
        </UiStack>
    </UiStack>;
};

export default SAMLConfigureIDPPageComponent;
