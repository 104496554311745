import { routePaths } from '../../../common/constants/routePaths';

export default function buildTitleMap(hostMode: boolean) {
    const includesPathTitle = new Map([
        // Common routes
        [ 'existingaccount', 'CLIENT_EXISTING_ACCOUNT_TITLE' ],
        [ 'missingaccount', 'CLIENT_MISSING_ACCOUNT_TITLE' ],
        [ 'completesignup', 'CLIENT_SIGNUP_TITLE' ],
        [ 'completeinvite', 'CLIENT_SIGNUP_TITLE' ],
        [ 'acceptinvite', 'CLIENT_ACCEPT_INVITE_TITLE' ],
        [ 'emailverification', 'CLIENT_EMAIL_VERIFICATION_TITLE' ],
        [ 'signup', 'CLIENT_SIGNUP_TITLE' ],
        [ 'automationkit', 'CLIENT_AUTOMATION_KIT_TITLE' ],
        [ 'unregistered', 'CLIENT_UNREGISTERED_TITLE' ],
        [ 'genericerror', 'CLIENT_GENERIC_ERROR_TITLE' ],
        [ 'home', 'CLIENT_HOME_TITLE' ],
        [ 'notifications', 'CLIENT_NOTIFICATION_TITLE' ],
        [ 'notification-settings', 'CLIENT_NOTIFICATION_SETTING_TITLE' ],
        [ 'profile', 'CLIENT_PREFERENCES_TITLE' ],
        [ 'personalaccesstoken', 'CLIENT_PERSONAL_ACCESS_TOKEN_TITLE' ],
        [ 'themesettings', 'CLIENT_THEME_SETTINGS_TITLE' ],
        [ 'accessibilitysettings', 'CLIENT_ACCESSIBILITY_SETTINGS_TITLE' ],
        [ 'navigationsettings', 'CLIENT_NAVIGATION_TITLE' ],
        [ 'privacyandsecuritysettings', 'CLIENT_PRIVACY_AND_SECURITY_TITLE' ],
        [ 'home', 'CLIENT_HOME_TITLE' ],

        // Old routes
        [ 'admin', 'CLIENT_ADMINISTRATION_TITLE' ],
        [ 'robots', 'CLIENT_ROBOTS_TITLE' ],
        [ 'buypro', 'CLIENT_ECOMMERCE_TITLE' ],
        [ 'service', 'CLIENT_SERVICE_INSTANCE_DETAILS_TITLE' ],
        [ 'serviceinstances', 'CLIENT_SERVICEINSTANCE_TITLE' ],
        [ 'users', 'CLIENT_USERS_TITLE' ],
        [ 'licensing', 'CLIENT_LICENSE_TITLE' ],
        [ 'resourcecenter', 'CLIENT_RESOURCE_TITLE' ],
        [ 'auditlog', 'CLIENT_AUDITLOG_TITLE' ],
        [ 'organizations', 'CLIENT_ORGANIZATION_TITLE' ],
        [ 'externalapps', 'CLIENT_EXTERNAL_APPLICATIONS_TITLE' ],
        [ 'groups', 'CLIENT_GROUPS_TITLE' ],
        [ 'authsettings', 'CLIENT_AUTHENTICATION_SETTINGS_TITLE' ],
        [ 'azure', 'CLIENT_AUTHENTICATION_SETTINGS_TITLE' ],
        [ 'authenticationsettings', 'CLIENT_AUTHENTICATION_SETTINGS_TITLE' ],
        [ 'sessionpolicy', 'CLIENT_SESSION_POLICY_TITLE' ],
        [ 'iprestriction', 'CLIENT_IP_RESTRICTION_TITLE' ],
        [ 'manageaccess', 'CLIENT_MANAGE_ACCESS' ],
        [ 'encryption', 'CLIENT_ENCRYPTION_TITLE' ],
        [ 'accessrestriction', 'CLIENT_ACCESS_RESTRICTION_TITLE' ],
        [ 'logincustomization', 'CLIENT_LOGIN_CUSTOMIZATION_TITLE' ],

        // New routes
        // Global app routes
        [ 'resource-center', 'CLIENT_RESOURCE_TITLE' ],

        // Admin
        [ routePaths.admin, 'CLIENT_ADMINISTRATION_TITLE' ],
        [ routePaths.adminTenantCreate, 'CLIENT_TENANT_CREATE_TITLE' ],

        [ routePaths.adminIdentitiesUsers, 'CLIENT_USERS_TITLE' ],
        [ routePaths.adminIdentitiesRobots, 'CLIENT_ROBOTS_TITLE' ],
        [ routePaths.adminIdentitiesGroups, 'CLIENT_GROUPS_TITLE' ],

        [ routePaths.adminLicenses, 'CLIENT_LICENSE_TITLE' ],
        [ routePaths.adminLicensesBuyProPresets, 'CLIENT_ECOMMERCE_TITLE' ],

        [ routePaths.adminAudit, 'CLIENT_AUDITLOG_TITLE' ],

        [ routePaths.adminAiTrustSettings, 'CLIENT_AI_TRUST_LAYER_TITLE' ],

        [ routePaths.adminSecurity, 'CLIENT_AUTHENTICATION_SETTINGS_TITLE' ],
        [ routePaths.adminSecurityAuthentication, 'CLIENT_AUTHENTICATION_SETTINGS_TITLE' ],
        [ routePaths.adminSecuritySessionPolicy, 'CLIENT_SESSION_POLICY_TITLE' ],
        [ routePaths.adminSecurityIpRestriction, 'CLIENT_IP_RESTRICTION_TITLE' ],
        [ routePaths.adminSecurityEncryption, 'CLIENT_ENCRYPTION_TITLE' ],
        [ routePaths.adminSecurityAccessRestriction, 'CLIENT_ACCESS_RESTRICTION_TITLE' ],

        [ routePaths.adminExternalApps, 'CLIENT_EXTERNAL_APPLICATIONS_TITLE' ],
        [ routePaths.adminExternalAppsOauth, 'CLIENT_EXTERNAL_APPLICATIONS_TITLE' ],
        [ routePaths.adminExternalAppsPat, 'CLIENT_PERSONAL_ACCESS_TOKEN_TITLE' ],
        [ routePaths.adminExternalAppsRegistrations, 'CLIENT_APP_REGISTRATIONS_TITLE' ],

        [ routePaths.adminUserConsent, 'CLIENT_USER_CONSENT_TITLE' ],

        [ routePaths.adminTenant, 'CLIENT_TENANT' ],
        [ routePaths.adminTenantServices, 'CLIENT_SERVICES_TITLE' ],
        [ routePaths.adminTenantLicenses, 'CLIENT_LICENSE_TITLE' ],
        [ routePaths.adminTenantTags, 'CLIENT_TAGS' ],
        [ routePaths.adminTenantTagsLabels, 'CLIENT_TAGS_LABELS_TITLE' ],
        [ routePaths.adminTenantTagsLabelsAdd, 'CLIENT_TAGS_LABELS_TITLE' ],
        [ routePaths.adminTenantTagsLabelsEdit, 'CLIENT_TAGS_LABELS_TITLE' ],
        [ routePaths.adminTenantTagsProperties, 'CLIENT_TAGS_PROPERTIES_TITLE' ],
        [ routePaths.adminTenantTagsPropertiesAdd, 'CLIENT_TAGS_PROPERTIES_TITLE' ],
        [ routePaths.adminTenantTagsPropertiesAddValues, 'CLIENT_TAGS_PROPERTIES_TITLE' ],
        [ routePaths.adminTenantTagsPropertiesEdit, 'CLIENT_TAGS_PROPERTIES_TITLE' ],
        [ routePaths.adminTenantTagsPropertiesEditValues, 'CLIENT_TAGS_PROPERTIES_TITLE' ],
        [ routePaths.adminTenantManageAccess, 'CLIENT_MANAGE_ACCESS_TITLE' ],
        [ routePaths.adminTenantManageAccessCheckAccess, 'CLIENT_CHECK_ACCESS_TITLE' ],
        [ routePaths.adminTenantGateway, 'CLIENT_GATEWAY_TITLE' ],
        [ routePaths.adminTenantAudit, 'CLIENT_AUDITLOG_TITLE' ],
        [ routePaths.adminTenantSettings, 'CLIENT_TENANT_SETTINGS_TITLE' ],

        [ routePaths.adminSettings, 'CLIENT_GLOBAL_SETTINGS_TITLE' ],

        [ routePaths.adminSettingsGeneral, 'CLIENT_ACCOUNT_GENERAL_SETTINGS_TITLE' ],
        [ routePaths.adminSettingsAdvanced, 'CLIENT_ACCOUNT_ADVANCED_SETTINGS_TITLE' ],

        // On-prem
        [ 'organizations', 'CLIENT_ORGANIZATION_TITLE' ],
    ]);

    const settingsTitle = hostMode ? 'CLIENT_GLOBAL_SETTINGS_TITLE' : 'CLIENT_ACCOUNT_SETTINGS_TITLE';

    includesPathTitle.set('emailsettings', settingsTitle);
    includesPathTitle.set('settings', settingsTitle);
    includesPathTitle.set(routePaths.adminEmailSettings, settingsTitle);

    return includesPathTitle;
}
