import { useUiDataContext } from '@experiences/ui-common';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import type { DataManager } from '@uipath/apollo-angular-elements';
import {
    ApDataGridColumn,
    ApDataGridColumnSearchFilter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridWrapper,
} from '@uipath/portal-shell-react';
import type { IColumn } from '@uipath/portal-shell-types/components/angular-elements';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import { UiDatePicker } from '../common/UiDatePicker/UiDatePicker';
import type {
    AuditEventDto,
    IAuditDetailsContext,
} from './interfaces/auditLog';
import { getSearchSourceFactory } from './util/AuditGridUtils';
import { useAuditGridViewModel } from './util/AuditGridViewModel';

const useStyles = makeStyles((theme) => ({
    divider: {
        width: '.5px',
        height: '24px',
        margin: '0px 12px 0px 8px',
    },
    timeFilterButton: { '&:focus, &:hover': { backgroundColor: `${theme.palette.semantic.colorBackgroundHover} !important` } },
    closeIcon: {
        strokeWidth: 1,
        stroke: theme.palette.semantic.colorIconDefault,
        '&:hover': {
            color: theme.palette.semantic.colorErrorIcon,
            stroke: theme.palette.semantic.colorErrorIcon,
        },
    },
}));

export const AuditGridComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ dataManager, setDataManager ] = useState<DataManager<AuditEventDto> | null>(null);

    const {
        data, setData,
    } = useUiDataContext<IAuditDetailsContext>();

    const {
        events, sources, loading, validating, filters, sourceFilter, categoryFilter, actionFilter, timeFilter, clearFilters,
    } = useAuditGridViewModel();

    const columns: Array<IColumn<any>> = useMemo(() => [
        {
            property: 'createdOn',
            title: translate({ id: 'CLIENT_DATE_CREATED_UTC' }),
            sortable: false,
            visible: true,
            primary: true,
        },
        {
            property: 'actorName',
            title: translate({ id: 'CLIENT_USER' }),
            sortable: false,
            visible: true,
        },
        {
            property: 'eventSource',
            title: translate({ id: 'CLIENT_SOURCE' }),
            sortable: false,
            visible: true,
            searchFilterOpts: {
                multiple: true,
                value: sourceFilter.suggestValue,
                searchSourceFactory: (search) => getSearchSourceFactory(
                    search,
                    sources?.map(s => s.name ?? s.id)
                ),
            },
        },
        {
            property: 'eventTarget',
            title: translate({ id: 'CLIENT_CATEGORY' }),
            sortable: false,
            visible: true,
            searchFilterOpts: {
                multiple: true,
                disabled: !sourceFilter.value,
                value: categoryFilter.suggestValue,
                searchSourceFactory: (search) => getSearchSourceFactory(
                    search,
                    sources?.filter(s => sourceFilter.value?.includes(s.name ?? s.id))
                        .flatMap(s => s.eventTargets)
                        .map(s => s.name ?? s.id)
                ),
            },
        },
        {
            property: 'eventType',
            title: translate({ id: 'CLIENT_ACTION' }),
            sortable: false,
            visible: true,
            searchFilterOpts: {
                multiple: true,
                disabled: !categoryFilter.value,
                value: actionFilter.suggestValue,
                searchSourceFactory: (search) => getSearchSourceFactory(
                    search,
                    sources?.flatMap(s => s.eventTargets)
                        .filter(s => categoryFilter.value?.includes(s.name ?? s.id))
                        .flatMap(s => s.eventTypes)
                        .map(s => s.name ?? s.id)
                ),
            },
        },

    ], [ translate, sourceFilter, categoryFilter, actionFilter, sources ]);

    const handleDetailsChange = useCallback((rowId: number) => (incrementer = 1) => {
        const id = rowId + incrementer;

        const apDataGrid = document.querySelector('web-ap-grid-new')?.shadowRoot;

        // scroll to nearby row prior to selecting element
        data.virtualScrollManager?.scrollTo(id - 2);

        // find the row to click based off the incrementer
        const row: HTMLDivElement | null | undefined = apDataGrid?.querySelector(`[data-row-index="${id}"]`);

        // if the row exists, click it - this will trigger the rowClick handler on the grid
        if (row) {
            row.click();
        }
    }, [ data.virtualScrollManager ]);

    const handleRowClick = useCallback(({ row }: { row: AuditEventDto }) => {
        // if the infinite scroll gets stuck for some reason, the user can click
        // on the last row to force a range load of the next set of data.
        // this is a safety net to ensure a user never gets into a broken state.
        if (+row.id === -1) {
            const length = (dataManager?.length ?? 0) - 1;

            data.virtualScrollManager?.rangeLoad({
                start: length,
                end: length,
            });

            return;
        }

        setData(prev => ({
            ...prev,
            row: {
                ...row,
                next: handleDetailsChange(row.rowId),
            },
        }));
    }, [ data.virtualScrollManager, dataManager?.length, handleDetailsChange, setData ]);

    return <ApDataGridWrapper<AuditEventDto>
        data={events}
        loading={loading || validating}
        allowHighlight
        virtualScroll
        selectable={false}
        rowClick={handleRowClick}
        onGridApi={(managers) => {
            setDataManager(managers.dataManager);
            setData(prev => ({
                ...prev,
                filterManager: managers.filterManager as any,
                virtualScrollManager: managers.virtualScrollManager,
            }));
        }}>

        <ApDataGridHeader search={false}>
            <ApDataGridHeaderButton
                type='inline'
                id="timeFilter"
                render={() =>
                    <>
                        <Button
                            variant='text'
                            color='inherit'
                            aria-controls={timeFilter.open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={timeFilter.open ? 'true' : undefined}
                            className={classes.timeFilterButton}
                            onClick={timeFilter.toggle}
                            endIcon={timeFilter.hasValue ?
                                <ClearIcon
                                    className={classes.closeIcon}
                                    onClick={timeFilter.clear} /> :
                                <KeyboardArrowDownIcon />}
                        >
                            {timeFilter.label}
                        </Button>
                        <UiDatePicker
                            anchorEl={timeFilter.anchorEl}
                            open={timeFilter.open}
                            value={timeFilter.value}
                            setValue={timeFilter.set}
                            onClose={timeFilter.toggle}
                            calendar={{ disableFuture: true }} />
                    </>}
            />
            <ApDataGridHeaderButton
                id="resetFilters"
                type="inline"
                render={() => <div style={{
                    display: (filters.length > 0 || timeFilter.hasValue) ? 'flex' : 'none',
                    alignItems: 'center',
                }}>
                    <Divider className={classes.divider} />
                    <Button
                        color="primary"
                        variant="text"
                        onClick={() => clearFilters()}
                    >
                        {translate({ id: 'CLIENT_CLEAR_ALL' })}
                    </Button>
                </div>}
            />
        </ApDataGridHeader>

        {columns.map((column) => (
            <ApDataGridColumn
                key={column.property}
                property={column.property}
                title={column.title}
                searchable={column.searchable}
                {...column.sortable !== undefined ? { sortable: column.sortable } : {}}
            >
                {column.searchFilterOpts ? <ApDataGridColumnSearchFilter {...column.searchFilterOpts} /> : null}
            </ApDataGridColumn>
        ))}

    </ApDataGridWrapper>;
};
